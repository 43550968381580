import { styled } from '@linaria/react';

export const BookTableOfContentsGlobalStyles = `
  @page {
    width: 8in;
    height: 10in;
    margin: 72px 137px;
  }

  body {
    print-color-adjust: exact !important;
    -webkit-print-color-adjust: exact !important;
    background: white;
  }
`;

export const Content = styled.div`
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 42.48pt;
`;

export const Title = styled.span`
  font-family: 'TimesRegular';
  text-align: center;
  font-size: 23.01pt;
  color: var(--surface-always-dark);
`;

export const SectionList = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 16px;
`;

export const SectionListItem = styled.div`
  display: grid;
  grid-template-columns: auto max-content;
  grid-template-areas: 'chapter page';
  align-items: end;
  gap: 0 0.25rem;
  margin-top: 1pt;
`;

export const SectionItemTitle = styled.span`
  font-family: 'TimesRegular';
  font-size: 12pt;
  color: var(--surface-always-dark);

  grid-area: chapter;
  position: relative;
  overflow: hidden;

  &::after {
    position: absolute;
    padding-left: 0.25ch;
    content: ' . . . . . . . . . . . . . . . . . . . ' '. . . . . . . . . . . . . . . . . . . . . . . '
      '. . . . . . . . . . . . . . . . . . . . . . . ' '. . . . . . . . . . . . . . . . . . . . . . . '
      '. . . . . . . . . . . . . . . . . . . . . . . ';
    text-align: right;
  }
`;

export const SectionItemPage = styled.span`
  font-family: 'TimesRegular';
  text-align: center;
  font-size: 12pt;
  color: var(--surface-always-dark);
  text-align: right;
  width: 32px;
`;
