import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import axios from 'axios';

import {
  BookTableOfContentsGlobalStyles,
  Content,
  SectionItemPage,
  SectionItemTitle,
  SectionList,
  SectionListItem,
  Title,
} from './BookTableOfContents.styles';

interface RemoteData {
  sections: Array<{
    title: string;
    pageNumber: number;
  }>;
}

const TEST_DATA: RemoteData = {
  sections: [
    {
      title:
        'A Very Long Message for Alden That Will Fill More Than One Line Hopefully Not Yet Still Not Yet This Is A Long Title',
      pageNumber: 4,
    },
    { title: 'Bringing Alden Home', pageNumber: 6 },
    { title: 'Month One', pageNumber: 10 },
    { title: 'Month Two', pageNumber: 14 },
    { title: 'Month Three', pageNumber: 18 },
    { title: 'Month Four', pageNumber: 22 },
    { title: 'Month Five', pageNumber: 26 },
    { title: 'Month Six', pageNumber: 30 },
    { title: 'Month Seven', pageNumber: 34 },
    { title: 'Month Eight', pageNumber: 38 },
    { title: 'Month Nine', pageNumber: 42 },
    { title: 'Month Ten', pageNumber: 46 },
    { title: 'Month Eleven', pageNumber: 50 },
    { title: 'One Year With Alden', pageNumber: 54 },
  ],
};

export function BookTableOfContents() {
  const [data, setData] = useState<RemoteData | null>(null);

  useEffect(() => {
    axios
      .get<RemoteData>('/__data__.json')
      .then(
        (response) => response.data,
        () => TEST_DATA,
      )
      .then(async (remoteData) => {
        setData(remoteData);
      });
  }, []);

  if (data === null) {
    return null;
  }

  return (
    <>
      <Helmet>
        <style>{BookTableOfContentsGlobalStyles}</style>
      </Helmet>
      <Content>
        <Title>Table of Contents</Title>
        <SectionList>
          {data.sections.map((section) => (
            <SectionListItem key={section.pageNumber}>
              <SectionItemTitle>{section.title}&nbsp;</SectionItemTitle>
              <SectionItemPage>{section.pageNumber}</SectionItemPage>
            </SectionListItem>
          ))}
        </SectionList>
      </Content>
    </>
  );
}
